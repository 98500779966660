<template>
    <b-card title="Načini letovanja">
        <Table class="mt-2" :columns="columns" :data="items" v-slot="props">

            <span v-if="props.column.display_type === 3" class="d-flex justify-content-center">
                <b-button  variant="warning" @click="openEditSubmissionTypeModal(props.row)"><fa icon="edit"/></b-button>
            </span>
            <span v-else>
                {{ props.formattedRow[props.column.field] }}
            </span>
        </Table>
        <edit-submission-type-modal ref="editSubmissionTypeModal" @itemEdited="loadData"/>
    </b-card>
</template>

<script>
    import {BCard, BButton} from 'bootstrap-vue'
    import Table from '@/views/components/Table.vue'
    import EditSubmissionTypeModal from '@/views/admin/submission/components/EditSubmissionTypeModal.vue'
    export default {
        components: {
            Table,
            BCard,
            BButton,
            EditSubmissionTypeModal
        },
        data() {
            return {
                columns: [
                    {
                        field: 'text',
                        label: 'Način letovanja',
                        tdClass: 'align-middle'
                    },
                    {
                        field: 'description',
                        label: 'Opis',
                        tdClass: 'align-middle'
                    },
                    {
                        field: 'actions',
                        label: 'Dejanja',
                        tdClass: 'align-middle',
                        display_type: 3
                    }
                ],
                items: []
            }
        },
        methods: {
            async loadData() {
                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.get('/api/private/v1/submission_type/')
                    this.items = response.data ?? []
                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            openEditSubmissionTypeModal(data) {
                this.$refs.editSubmissionTypeModal.show(data)
            }
        },

        async mounted() {
            await this.loadData()
        }
    }
</script>

<style scoped>
.text-primary {

}
</style>
