<template>
    <b-modal ref="modal" title="Uredi način letovanja" centered no-close-on-backdrop @cancel="makeEmptyObject" @close="makeEmptyObject" @hide="makeEmptyObject">
        <template #default>
            <validation-observer ref="validation">
                <b-form-group label="Način letovanja">
                    <validation-provider #default="{errors}" name="Način letovanja">
                        <b-form-input v-model="submissionTypeObject.text" disabled/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Opis">
                    <validation-provider #default="{errors}" name="Opis" rules="min:1">
                        <b-form-textarea v-model="submissionTypeObject.description"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>

            </validation-observer>
        </template>

        <template #modal-footer="{ hide }">
            <b-button variant="outline-primary" @click="hide">
                Zapri
            </b-button>
            <b-button variant="primary" @click="edit">
                Uredi
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import {BButton, BModal, BFormGroup, BFormInput, BFormTextarea} from 'bootstrap-vue'
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {email, required, min, max, password} from '@core/utils/validations/validations'

    export default {
        components: {
            BModal,
            BButton,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                submissionTypeObject: {
                    text:'',
                    description:''
                },
                required,
                email,
                min,
                max,
                password
            }
        },
        methods: {
            show(data) {
                this.submissionTypeObject = JSON.parse(JSON.stringify(data))
                this.$refs.modal.show()
            },
            async edit() {
                const isValid = await this.$refs.validation.validate()
                if (!isValid) return

                try {
                    this.$store.commit('app/START_LOADING')
                    await this.$http.put(`/api/private/v1/submission_type/${this.submissionTypeObject.id}`, this.submissionTypeObject)
                    this.$refs.modal.hide()
                    this.$emit('itemEdited')
                    this.$printSuccess('Urejanje je bilo uspešno')
                    this.makeEmptyObject()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri urejanju')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            makeEmptyObject() {
                this.submissionTypeObject = this.$options.data.call(this).submissionTypeObject
            }
        }
    }
</script>